<script>
import { mapState } from 'vuex';
import * as remortgageActions from '@/store/remortgage/actions';
import * as personActions from '@/store/persons/actions';
import * as lenderActions from '@/store/lenders/actions';

export default {
  name: 'FetchFromApi',
  async mounted() {
    this.$store.commit('setLoading', true);
    if (this.entityType === 'remortgage') {
      this.$store.commit('setRemortgageId', this.entityId);
      await this.$store.dispatch(remortgageActions.REMORTGAGE_FETCH, this.entityId);

      await Promise.all([
        this.personsFetchRemortgageClients(),
        this.personsFetchAdditionalToePersons(),
        this.personsFetchRemovedToePersons(),
        this.personFetchRemortgageOccupiers(),
        this.personsFetchFirmPersons(),
        this.fetchLenders(),
        this.fetchSubmissionStatus(),
      ]);
    }

    this.$store.commit('setLoading', false);
  },
  props: ['entityType', 'entityId'],
  computed: mapState({
    loading: (state) => state.loading,
    remortgage: (state) => state.remortgage,
    firm: (state) => state.firm,
    clientIds: (state) => state.remortgage.clientIds,
    occupierIds: (state) => state.remortgage.occupierIds,
    personIds: (state) => state.firm.personIds,
  }),
  methods: {
    async personsFetchRemortgageClients() {
      if (this.remortgage.clientIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_REMORTGAGE_CLIENTS, this.remortgage.clientIds);
      }
    },
    async personsFetchAdditionalToePersons() {
      if (this.remortgage.personsToBeAddedToTitleIds.length) {
        await this.$store.dispatch(
          personActions.PERSONS_FETCH_ADDITIONAL_TOE_PERSONS,
          this.remortgage.personsToBeAddedToTitleIds,
        );
      }
    },
    async personsFetchRemovedToePersons() {
      if (this.remortgage.personsToBeRemovedFromTitleIds.length) {
        await this.$store.dispatch(
          personActions.PERSONS_FETCH_REMOVED_TOE_PERSONS,
          this.remortgage.personsToBeRemovedFromTitleIds,
        );
      }
    },
    async personFetchRemortgageOccupiers() {
      if (this.remortgage.occupierIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_OCCUPIERS, this.remortgage.occupierIds);
      }
    },
    async personsFetchFirmPersons() {
      if (this.firm.personIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_FIRM_PERSONS, this.firm.personIds);
      }
    },
    async fetchLenders() {
      await this.$store.dispatch(lenderActions.LENDERS_FETCH_ALL);
    },
    async fetchSubmissionStatus() {
      await this.$store.dispatch(remortgageActions.REMORTGAGE_FETCH_SUBMISSION_STATUS, this.entityId);
    },
  },
};
</script>

<template>
  <div class="row align-items-center"></div>
</template>
