<script>
import { mapActions, mapState } from 'vuex';
import * as saleActions from '@/store/sale/actions';
import * as buildingActions from '@/store/building/actions';
import * as personActions from '@/store/persons/actions';
import * as firmActions from '@/store/firm/actions';
import * as lenderActions from '@/store/lenders/actions';

export default {
  name: 'FetchFromApi',
  async mounted() {
    this.$store.commit('setLoading', true);
    if (this.entityType === 'sale') {
      this.$store.commit('setSaleId', this.entityId);
      await this.$store.dispatch(saleActions.SALE_FETCH, this.entityId);
      this.$store.commit('setBuildingId', this.sale.buildingId);
      await this.$store.dispatch(buildingActions.BUILDING_FETCH, this.sale.buildingId);
      this.$store.commit('setFirmId', this.sale.estateAgentId);
      await this.$store.dispatch(firmActions.FIRM_FETCH, this.sale.estateAgentId);

      await this.personsFetchSaleClients();
      await this.personFetchSaleOccupiers();
      await this.personsFetchFirmPersons();
      await this.fetchDocuments(this.entityId);
      await this.fetchLenders();
      await this.fetchSubmissionStatus();
    }

    this.$store.commit('setLoading', false);
  },
  props: ['entityType', 'entityId'],
  computed: mapState({
    loading: (state) => state.loading,
    sale: (state) => state.sale,
    purchase: (state) => state.purchase,
    firm: (state) => state.firm,
    clientIds: (state) => state.sale.clientIds,
    occupierIds: (state) => state.sale.occupierIds,
    personIds: (state) => state.firm.personIds,
    documents: (state) => state.documents.documents,
  }),
  methods: {
    ...mapActions('documents', ['fetchDocuments']),
    async personsFetchSaleClients() {
      if (this.sale.clientIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_SALE_CLIENTS, this.sale.clientIds);
      }
    },
    async personFetchSaleOccupiers() {
      if (this.sale.occupierIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_OCCUPIERS, this.sale.occupierIds);
      }
    },
    async personsFetchFirmPersons() {
      if (this.firm.personIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_FIRM_PERSONS, this.firm.personIds);
      }
    },
    async fetchLenders() {
      await this.$store.dispatch(lenderActions.LENDERS_FETCH_ALL);
    },
    async fetchSubmissionStatus() {
      await this.$store.dispatch(saleActions.SALE_FETCH_SUBMISSION_STATUS, this.entityId);
    },
  },
};
</script>

<template>
  <div class="row align-items-center"></div>
</template>
