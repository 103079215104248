<script>
import { mapState } from 'vuex';
import FetchFromApi from '@/components/FetchFromApi';
import FetchPurchaseFromApi from '@/components/FetchPurchaseFromApi';
import FetchRemortgageFromApi from '@/components/FetchRemortgageFromApi';
import Loader from '@/components/Loader';

export default {
  name: 'formWrapper',
  components: { FetchFromApi, FetchPurchaseFromApi, FetchRemortgageFromApi, Loader },
  props: ['entityType', 'entityId'],
  computed: mapState({
    loading: (state) => state.loading,
  }),
};
</script>
<template>
  <div>
    <fetch-from-api v-if="entityType === 'sale'" :entityType="entityType" :entityId="entityId" />
    <fetch-purchase-from-api v-if="entityType === 'purchase'" :entityType="entityType" :entityId="entityId" />
    <fetch-remortgage-from-api v-if="entityType === 'remortgage'" :entityType="entityType" :entityId="entityId" />
    <div class="card card-body">
      <loader v-if="loading">Loading Case Data...</loader>
      <slot v-else name="form-content"></slot>
    </div>
  </div>
</template>
