<script>
import { mapState } from 'vuex';
import * as purchaseActions from '@/store/purchase/actions';
import * as buildingActions from '@/store/building/actions';
import * as personActions from '@/store/persons/actions';
import * as firmActions from '@/store/firm/actions';
import * as lenderActions from '@/store/lenders/actions';

export default {
  name: 'FetchFromApi',
  async mounted() {
    this.$store.commit('setLoading', true);
    if (this.entityType === 'purchase') {
      this.$store.commit('setPurchaseId', this.entityId);
      await this.$store.dispatch(purchaseActions.PURCHASE_FETCH, this.entityId);
      this.$store.commit('setBuildingId', this.purchase.buildingId);
      await this.$store.dispatch(buildingActions.BUILDING_FETCH, this.purchase.buildingId);
      this.$store.commit('setFirmId', this.purchase.estateAgentId); // TODO - WHAT IS THERE IS NO ESTATE AGENT ?
      await this.$store.dispatch(firmActions.FIRM_FETCH, this.purchase.estateAgentId);

      await this.personsFetchPurchaseClients();
      await this.personFetchPurchaseOccupiers();
      await this.personsFetchFirmPersons();
      await this.fetchLenders();
    }

    this.$store.commit('setLoading', false);
  },
  props: ['entityType', 'entityId'],
  computed: mapState({
    loading: (state) => state.loading,
    purchase: (state) => state.purchase,
    firm: (state) => state.firm,
    clientIds: (state) => state.purchase.clientIds,
    occupierIds: (state) => state.purchase.occupierIds,
    personIds: (state) => state.firm.personIds,
  }),
  methods: {
    async personsFetchPurchaseClients() {
      if (this.purchase.clientIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_PURCHASE_CLIENTS, this.purchase.clientIds);
      }
    },
    async personFetchPurchaseOccupiers() {
      if (this.purchase.occupierIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_OCCUPIERS, this.purchase.occupierIds);
      }
    },
    async personsFetchFirmPersons() {
      if (this.firm.personIds.length) {
        await this.$store.dispatch(personActions.PERSONS_FETCH_FIRM_PERSONS, this.firm.personIds);
      }
    },
    async fetchLenders() {
      await this.$store.dispatch(lenderActions.LENDERS_FETCH_ALL);
    },
  },
};
</script>

<template>
  <div class="row align-items-center"></div>
</template>
